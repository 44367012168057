<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-menu :selectedKeys="current" @select="changeSelect" mode="horizontal">
      <a-menu-item key="base64"> Base64 </a-menu-item>
      <a-menu-item key="urlencode"> URL </a-menu-item>
      <a-menu-item key="hex"> Hex </a-menu-item>
    </a-menu>
    <div style="margin-top: 20px">
      <div v-if="current.indexOf('base64') !== -1">
        <div>
          <a-col :span="12">
            <a-textarea
              v-model="base64_origin"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%"
              placeholder="想要编码/解码的内容粘贴在这里"
              allowClear
            >
            </a-textarea>
          </a-col>
          <a-col :span="12">
            <a-textarea
              v-model="base64_result"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%; margin-left: 1%"
              placeholder="结果"
              allowClear
            >
            </a-textarea>
          </a-col>
        </div>
        <div style="margin-top: 20px; float: right">
          <a-checkbox v-model="base64_url">URLSafe</a-checkbox>
          <a-checkbox v-model="base64_multi">多行</a-checkbox>
          <a-button type="primary" @click="base64_deal('encode')"
            >Base64编码</a-button
          >
          <a-button
            type="primary"
            @click="base64_deal('decode')"
            style="margin-left: 10px"
            >Base64解码</a-button
          >
        </div>
      </div>
      <div v-if="current.indexOf('urlencode') !== -1">
        <div>
          <a-col :span="12">
            <a-textarea
              v-model="url_origin"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%"
              placeholder="想要编码/解码的内容粘贴在这里"
              allowClear
            >
            </a-textarea>
          </a-col>
          <a-col :span="12">
            <a-textarea
              v-model="url_result"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%; margin-left: 1%"
              placeholder="结果"
              allowClear
            >
            </a-textarea>
          </a-col>
        </div>
        <div style="margin-top: 20px; float: right">
          <a-button type="primary" @click="url_deal('encode')"
            >URL编码</a-button
          >
          <a-button
            type="primary"
            @click="url_deal('decode')"
            style="margin-left: 10px"
            >URL解码</a-button
          >
        </div>
      </div>
      <div v-if="current.indexOf('hex') !== -1">
        <div>
          <a-col :span="12">
            <a-textarea
              v-model="hex_origin"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%"
              placeholder="想要编码/解码的内容粘贴在这里"
              allowClear
            >
            </a-textarea>
          </a-col>
          <a-col :span="12">
            <a-textarea
              v-model="hex_result"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%; margin-left: 1%"
              placeholder="结果"
              allowClear
            >
            </a-textarea>
          </a-col>
        </div>
        <div style="margin-top: 20px; float: right">
          <a-select v-model="hex_encoding" style="width: 100px">
            <a-select-option value="utf-8">utf-8</a-select-option>
          </a-select>
          <a-button
            type="primary"
            @click="hex_deal('encode')"
            style="margin-left: 10px"
            >Hex编码</a-button
          >
          <a-button
            type="primary"
            @click="hex_deal('decode')"
            style="margin-left: 10px"
            >Hex解码</a-button
          >
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { base64, url_encode, hex } from "@/api/tool";
export default {
  data() {
    return {
      current: ["base64"],
      base64_origin: "",
      base64_result: "",
      base64_multi: false,
      base64_url: false,
      url_origin: "",
      url_result: "",
      hex_origin: "",
      hex_result: "",
      hex_encoding: "utf-8",
    };
  },
  methods: {
    changeSelect(item) {
      this.current = item.keyPath;
    },
    base64_deal(base64_action) {
      var data = {
        base64_action: base64_action,
        base64_origin: this.base64_origin,
        base64_multi: this.base64_multi,
        base64_url: this.base64_url,
      };
      base64(data)
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.error(res.data.msg);
          } else {
            this.base64_result = res.data.msg;
          }
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    url_deal(url_action) {
      var data = {
        url_action: url_action,
        url_origin: this.url_origin,
      };
      url_encode(data)
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.error(res.data.msg);
          } else {
            this.url_result = res.data.msg;
          }
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    hex_deal(hex_action) {
      var data = {
        hex_action: hex_action,
        hex_origin: this.hex_origin,
        hex_encoding: this.hex_encoding,
      };
      hex(data)
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.error(res.data.msg);
          } else {
            this.hex_result = res.data.msg;
          }
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
  },
  mounted() {},
};
</script>
