<template>
  <div></div>
</template>

<script>
import { ethers } from "ethers";
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    const INFURA_ID = "b087e521a1d14583b1e5c52be948c0b0";
    const provider = new ethers.providers.JsonRpcProvider(
      `https://mainnet.infura.io/v3/${INFURA_ID}`
    );
    const main = async () => {
      const balance = await provider.getBalance(`vitalik.eth`);
      console.log(`ETH Balance of vitalik: ${ethers.formatEther(balance)} ETH`);
    };
    main();
  },
};
</script>
